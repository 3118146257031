import React from 'react';

const FeatureInfo = () => {
  return (
    <section className="bg-primary">
      <div className="container">
        <div className="row feature-info-02">
          <div className="col-sm-4 py-4 text-center">
            <i className="flaticon-online-learning-1 fa-3x text-white"></i>
            <h4 className="fw-5 mt-3 mb-0 text-white">35+ Digital Courses</h4>
            <p className="mb-0 text-white">Learn, Grow & Succeed</p>
          </div>
          <div className="col-sm-4 py-4 text-center">
            <i className="flaticon-lock fa-3x text-white"></i>
            <h4 className="fw-5 mt-3 mb-0 text-white">Lifetime Access</h4>
            <p className="mb-0 text-white">Education for a Brighter Tomorrow</p>
          </div>
          <div className="col-sm-4 py-4 text-center">
            <i className="flaticon-strategy fa-3x mt-2 text-white"></i>
            <h4 className="fw-5 mt-3 mb-0 text-white">Soft Skills</h4>
            <p className="mb-0 text-white">Mastering Soft Skills is Mastering the Art of Working with Hearts</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureInfo;
