import "./Design/Dashboard.css"
import Income from "./Income";
import DashboardProfile from "./DashboardProfile";
import AchieversSection from "../components/AchieversSection";
const Dashboard = () => {
    return <>
        <DashboardProfile />
        <Income />
        <AchieversSection />
    </>
};

export default Dashboard;