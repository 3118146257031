import React from 'react';
import InnerBanner from '../components/InnerBanner';
import AgreementSection from '../components/AgreementSection';

const Agreement  = () => {
    return <>
        <InnerBanner pageName='Affiliate Agreement' />
        <AgreementSection />
    </>
};

export default Agreement ;