import axios from "axios";
const packageJson = require('../../package.json');
const createSignature = () => {
    // const crypto = require('crypto');
    // const secretKey = '5d928cd2-77d1-4415-abbb-4f225f40ee65';

    // // Format the payload string (equivalent of C# string.Format)
    // const payloadForSignature = `${payload}-` + secretKey;

    // // Create HMACSHA256 using crypto module
    // const hmac = crypto.createHmac('sha256', secretKey);
    // hmac.update(payloadForSignature);

    // // Compute the hash and convert it to base64
    // return hmac.digest('base64');
    const secretKey = "5d928cd2-77d1-4415-abbb-4f225f40ee65"
    return secretKey;
}
export const getUserNameByGId = async (GrowniverseId: string) => {
    
    const signature = createSignature();
    var response = await axios.get(packageJson.apiUrl+"Auth?GrowniverseId=" + GrowniverseId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature
        },
    });
    return response.data;
}

export const putUserSignOpt = async (userData: any) => {
    
    const signature = createSignature();
    const response = await axios.put(packageJson.apiUrl+"Auth", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature
        },
    });
    return response.data;
}

export const putUserLogin = async (userData: any) => {
    debugger
    const signature = createSignature();
    const responseApi = await axios.post(packageJson.apiUrl+"Auth", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature
        },
    });
    return responseApi.data;
    
}