import React from 'react';
import InnerPolicyBanner from '../components/InnerPolicyBanner';
import PrivacyPolicySection from '../components/PrivacyPolicySection';
const LoginPrivacyPolicy = () => {
    return (
        <>
        <InnerPolicyBanner pageName='Privacy Policy' />
        <PrivacyPolicySection />
        </>
    );
};

export default LoginPrivacyPolicy;
