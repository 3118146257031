import React from 'react';
import { Link } from 'react-router-dom';

const AboutFeature: React.FC = () => {
  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center mb-4 mb-md-0">
            <div className="feature-info-04">
              <i className="feature-icon flaticon-lecture fz50"></i>
              <h4 className="feature-title my-3">Our Support Center</h4>
              <p>Your Satisfaction is our top Priority.</p>
              <Link to={'#'} className="btn btn-link" >Start Now</Link>
            </div>
          </div>
          <div className="col-md-4 text-center mb-4 mb-md-0">
            <div className="feature-info-04 black">
              <i className="feature-icon flaticon-online-learning-1 fz50"></i>
              <h4 className="feature-title my-3">Learn Digitaly with Us</h4>
              <p>We are Providing you the Best Education Possible at your Home.</p>
              <Link to={'#'} className="btn btn-link" >Start Now</Link>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="feature-info-04">
              <i className="feature-icon flaticon-guarantee fz50"></i>
              <h4 className="feature-title my-3">Focus on Success</h4>
              <p>Focus on the Journey not the Destination</p>
              <Link to={'#'} className="btn btn-link">Start Now</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFeature;
