import React from 'react';
import homeBanner1 from "../../../src/assets/img/home-banner1.jpg";
import homeBanner2 from "../../../src/assets/img/home-banner2.jpg";
import homeBanner3 from "../../../src/assets/img/home-banner3.jpg";
import homeBanner4 from "../../../src/assets/img/home-banner4.jpg";
import "./home-banner.css";


const HomeBanner = () => {
  return (
    <div className='home-banner'>
      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='image-wrapper'>
              <img src={homeBanner1} className="d-block w-100" alt="..." />
            </div>
            <div className='banner-content text-center'>
              <h1>Best Education from your Home </h1>
              <p>Don't change your learning, just change the way.</p>
            </div>
          </div>
          <div className="carousel-item active">
            <div className='image-wrapper'>
              <img src={homeBanner2} className="d-block w-100" alt="..." />
            </div>
            <div className='banner-content text-center'>
            <h1>Best Education from your Home </h1>
              <p>Don't change your learning, just change the way.</p>
            </div>
          </div>
          <div className="carousel-item active">
            <div className='image-wrapper'>
              <img src={homeBanner3} className="d-block w-100" alt="..." />
            </div>
            <div className='banner-content text-center'>
            <h1>Best Education from your Home </h1>
              <p>Don't change your learning, just change the way.</p>
            </div>
          </div>
          <div className="carousel-item active">
            <div className='image-wrapper'>
              <img src={homeBanner4} className="d-block w-100" alt="..." />
            </div>
            <div className='banner-content text-center'>
            <h1>Best Education from your Home </h1>
              <p>Don't change your learning, just change the way.</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HomeBanner;