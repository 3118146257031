import "../assets/css/package.css"
import basic from "../Photos/Package/Basic_Package.png"
import advanced from "../Photos/Package/Advanced_Package.png"
import silver from "../Photos/Package/Silver_Package.png"
import Gold from "../Photos/Package/Gold_Package.png"
import Platinum from "../Photos/Package/Platinum_Package.png"
import { Button, Link } from "@mui/material";

var packages = [
    { postarLink: basic, description: "Basic Package", packageAmount: 2000, discountAmount: 1500, actualAccount: 948 },
    { postarLink: advanced, description: "Advanced Package", packageAmount: 3500, discountAmount: 3000, actualAccount: 2200 },
    { postarLink: silver, description: "Silver Package", packageAmount: 4200, discountAmount: 3600, actualAccount: 2848 },
    { postarLink: Gold, description: "Gold Package", packageAmount: 9500, discountAmount: 7700, actualAccount: 6400 },
    { postarLink: Platinum, description: "Platinum Package", packageAmount: 17000, discountAmount: 13000, actualAccount: 9848 }];
const Package = () => {
    return <>
        <section style={{ background: "#343434" }}>
            <div>
                {packages.map((pack, index) => (
                    <div style={{ textAlign: "center", padding: "10px", borderColor: "white", borderStyle: "inset", marginBottom: "10px" }}>
                        <div>
                            <img src={pack.postarLink} itemType="png" width={"300px"} height={"450px"} alt="Growniverse Package"/>
                        </div>
                        <div style={{ color: "black", background: "#899ba1", width: "300px", display: "inline-grid" }}>
                            <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>
                                {pack.description}
                            </h2>
                        </div>
                        <div>
                            <h4 style={{ color: "white", textDecoration: "line-through" }}>
                                <span style={{ color: "white" }}>₹{pack.packageAmount}</span>
                            </h4>
                            <h3 style={{ color: "white", textDecoration: "line-through" }}>
                                <span style={{ color: "white" }}>₹{pack.discountAmount}</span>
                            </h3>
                            <h2 style={{ color: "red" }}>₹{pack.actualAccount}</h2>
                        </div>
                        <div>
                            <Button variant="contained" style={{ background: "#fa9602" }}>
                                <Link style={{ color: "black" }} href="/package-courses">Explore More</Link>
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </>
};

export default Package;