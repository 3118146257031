import React from "react";

const CourseInfoWidget: React.FC = () => {
  return (
    <div className="widget widget-course-info">
      <h6 className="widget-title">Course info</h6>
      <div className="widget-content">
        <ul className="list">
          <li>
            <b>Course Date :</b>
            <span>17 Feb 2020 - 20 May 2020</span>
          </li>
          <li>
            <b>Time :</b>
            <span>09:00 - 01:00</span>
          </li>
          <li>
            <b>Duration :</b>
            <span>90 Hours</span>
          </li>
          <li>
            <b>Lectures :</b>
            <span>23</span>
          </li>
          <li>
            <b>Levels :</b>
            <span>Beginner</span>
          </li>
          <li>
            <b>Enrolled :</b>
            <span>1874 Students</span>
          </li>
          <li>
            <b>Video :</b>
            <span>12 Hours</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseInfoWidget;
