import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/flaticon.css';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import HomePage from './pages/HomePage';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import Layout from './components/Layout';
import CourseDetailPage from './pages/CourseDetail';
import routes from './routes';
import Dashboard from './Dashboard/Dashboard';
import UserLayout from './Dashboard/UserLayout';
import Income from './Dashboard/Income';
import DashboardProfile from './Dashboard/DashboardProfile';
import Package from './Package/Package';
import PackageCourses from './Package/PackageCourses';
import Payment from './Package/Payment';
import Hold from './Dashboard/Hold';
import AdminLayout from './Admin/AdminLayout';
import KycAdmin from './Admin/KycAdmin';
import EmailConfirmation from './pages/EmailConfirmation';
import TermsConditions from './pages/TermsConditions';
import RefundPolicy from './pages/RefundPolicy';
import Agreement from './pages/Agreement';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoginPrivacyPolicy from './pages/LoginPrivacyPolicy';
import LoginTermsConditions from './pages/LoginTermsConditions';
import LoginAgreement from './pages/LoginAgreement';

function App() {
  React.useEffect(() => {
    // $('#myModal').modal('show');
  }, []);

  return (
    <>
      <Routes>
        <Route path={routes.home} element={<Layout><HomePage /></Layout>} />
        <Route path={routes.contactUs} element={<Layout><ContactPage /></Layout>} />
        <Route path={routes.aboutUs} element={<Layout><AboutPage /></Layout>} />
        <Route path={routes.courseDetail} element={<Layout><CourseDetailPage /></Layout>} />
        <Route path={"/privacy-policy"} element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path={"/refund-policy"} element={<Layout><RefundPolicy /></Layout>} />
        <Route path={"/terms"} element={<Layout><TermsConditions /></Layout>} />
        <Route path={"/agreement"} element={<Layout><Agreement /></Layout>} />
        <Route path={"/dashboard"} element={<UserLayout><Dashboard /></UserLayout>} />
        <Route path={"/income"} element={<UserLayout><Income /></UserLayout>} />
        <Route path={"/profile"} element={<UserLayout><DashboardProfile /></UserLayout>} />
        <Route path={"/package"} element={<UserLayout><Package /></UserLayout>} />
        <Route path={"/package-courses"} element={<UserLayout><PackageCourses /></UserLayout>} />
        <Route path={"/payment"} element={<UserLayout><Payment /></UserLayout>} />
        <Route path={"/hold"} element={<UserLayout><Hold /></UserLayout>} />
        <Route path={"/kyc-admin"} element={<AdminLayout><KycAdmin /></AdminLayout>} />
        <Route path={"/emailConfirmation"} element={<EmailConfirmation />} />
        <Route path={"/privacypolicy"} element={<LoginPrivacyPolicy />} />
        <Route path={"/termsconditions"} element={<LoginTermsConditions />} />
        <Route path={"/affiliateagreement"} element={<LoginAgreement />} />
      </Routes>
    </>
  );
}

export default App;
