import React, { useState, useEffect, useRef } from 'react';

const StatisticsSection = () => {
  const [counters, setCounters] = useState({
    students: 0,
    courses: 0,
    teachers: 0,
    awards: 0,
  });
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const targetValues = {
      students: 100,
      courses: 35,
      teachers: 8,
      awards: 20,   
    };

    // Define the type of entries in the IntersectionObserver callback
    const handleIntersection: IntersectionObserverCallback = (entries) => {
      const [entry] = entries;

      if (entry.isIntersecting && !hasAnimated) {
        const incrementCounters = () => {
          setCounters((prevCounters) => ({
            students: Math.min(prevCounters.students + 11, targetValues.students),
            courses: Math.min(prevCounters.courses + 2, targetValues.courses),
            teachers: Math.min(prevCounters.teachers + 2, targetValues.teachers),
            awards: Math.min(prevCounters.awards + 1, targetValues.awards),
          }));
        };

        const interval = setInterval(incrementCounters, 50);
        setTimeout(() => {
          clearInterval(interval);
        }, 2000);

        setHasAnimated(true);
      }
    };
    const sectionElement = sectionRef.current;
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (sectionElement) {
      observer.observe(sectionElement);
    }

    return () => {
      if (sectionElement) {
        observer.unobserve(sectionElement);
      }
    };
  }, [hasAnimated]);

  return (
    <section className="statistics bg-primary py-4" ref={sectionRef}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-lg-0 d-flex align-items-center">
            <i className="flaticon-user text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.students}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Happy Students</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-lg-0 d-flex align-items-center">
            <i className="flaticon-list-1 text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.courses}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Our Courses</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-sm-0 d-flex align-items-center">
            <i className="flaticon-studying text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.teachers}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Our Teachers</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex">
            <i className="flaticon-guarantee text-white fa-4x me-3 d-flex align-items-center"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.awards}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Awards Won</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
