import React from 'react';
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import { Link } from 'react-router-dom';
const PrivacyPolicySection = () => {
    return (
        <section>
            <div style={{ padding: "10px" }}>
                <h5>Growniverse Privacy Policy</h5>
                <p>
                    At Growniverse, We value the trust that our affiliates place in us when they join our affiliate program. This privacy policy outlines how we collect, use, and protect the personal information of our affiliates.
                </p>
                <h6>What Personal Information Do We Collect</h6>
                <p>
                    <b>We collect the following personal information from our affiliates:</b><br />
                    1.1 Name and Email Address: We collect name and email address when affiliates sign up for our affiliate program.<br />
                    1.2 Password: We collect a password from affiliates to secure their account.<br />
                    1.3 Payment Information: We collect payment information, such as email address or bank account details, to process commission payments.<br />
                    1.4 Website URL: We collect the website URL of our affiliates to track referrals and commissions.<br />
                    1.5 IP Address: We collect the IP address of our affiliates to track referrals and commissions.
                </p>
                <h6>How Do We Use Personal Information</h6>
                <p>
                    <b>We use personal information for the following purposes:</b><br />
                    2.1 Affiliate Program Administration: We use personal information to administer our affiliate program, including tracking referrals and commissions.<br />
                    2.2 Communication: We use personal information to communicate with our affiliates, including sending newsletters, commission statements, and other important updates.<br />
                    2.3 Payment Processing: We use personal information to process commission payments to our affiliates.<br />
                </p>
                <h6>How Do We Protect Personal Information</h6>
                <p>
                    <b>We take reasonable measures to protect personal information from unauthorized access, use, or disclosure, including:</b><br />
                    3.1 Secure Socket Layer (SSL) Encryption: We use SSL encryption to protect personal information transmitted over the internet.<br />
                    3.2 Firewalls and Access Controls: We use firewalls and access controls to restrict access to personal information.<br />
                    3.3 Data Backup and Recovery: We regularly back up personal information and have a disaster recovery plan in place.<br />
                </p>
                <h6>Sharing Personal Information</h6>
                <p>
                    <b>We never share personal information with third-party service providers:</b><br />
                    4.1 Payment Processors: We share payment information with payment processors to process commission payments.<br />
                    4.2 Email Service Providers: We share email addresses with email service providers to send newsletters and other important updates.<br />
                </p>
                <h6>Affiliate Rights</h6>
                <p>
                    <b>Affiliates have the following rights:</b><br />
                    5.1 Right to Access: Affiliates have the right to access their personal information.<br />
                    5.2 Right to Correct: Affiliates have the right to correct their personal information.<br />
                    5.3 Right to Erase: Affiliates have the right to erase their personal information.<br />
                    5.4 Right to Object: Affiliates have the right to object to the processing of their personal information.<br />
                </p>
                <h6>Changes to This Policy</h6>
                <p>
                    <b>We reserve the right to modify or update this privacy policy at any time without prior notice.</b>
                </p>
                <h6>Contact Us</h6>
                <p>
                    <b>If you have any questions or concerns about this privacy policy, please contact us at:</b><br />
                    Mail us :- <Link to={'mailto:admin@growniverse.com'} target='blank'>admin@growniverse.com</Link><br />
                </p>
                <p>
                    By participating in the Growniverse affiliate program, affiliates acknowledge that they have read, understood, and agree to abide by the terms and conditions of this privacy policy.
                </p>
                <img src={GrowniverseLogo} width={"20%"} alt='Growniverse'/>
                <p>
                    GROWNIVERSE<br />
                    A UNIVERSE WHERE YOU GROW
                </p>
            </div>
        </section>
    );
};

export default PrivacyPolicySection;
