import DashboardProfile from '../Dashboard/DashboardProfile';
import "../Dashboard/Design/Dashboard.css"
import "../components/WelcomeSection.css";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";
const KycAdmin = () => {
    const [pannelId, setPannelId] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    // Data for the table
    const rows = [
        {
            GrowniverseId: "G10024",
            Name: "Shyam Sunder Tejpal",
            Package: "Platinum",
            UnPaid: 2000,
            SevenDayIncome: 23000,
            TotalIncome: 120000,
        },
        {
            GrowniverseId: "G10025",
            Name: "John Doe",
            Package: "Gold",
            UnPaid: 1500,
            SevenDayIncome: 15000,
            TotalIncome: 80000,
        },
    ];
    const filteredRows = rows.filter(
        (row) =>
            row.GrowniverseId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.Package.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
            <DashboardProfile />
            <div className="header" style={{ display: "flex" }}>
                <Button variant="contained" onClick={() => setPannelId(2)}>Team</Button>
                <Button variant="contained">Kyc Details</Button>
                <Button variant="contained">Payouts</Button>
                <Button variant="contained" onClick={() => setPannelId(1)}>Payment Verifications</Button>
                <Button variant="contained" onClick={() => setPannelId(4)}>Turnover</Button>

            </div>
            {
                pannelId === 1 && (
                    <div style={{ background: "#343434", height: "500px", color: "white" }}>

                        <TableContainer >
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ color: "white" }}>GrowniverseId</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Name</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Package</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Amount</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Transaction-Id</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Date&Time</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Approve</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Cancel</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ color: "white" }}>
                                            G10024
                                        </TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Shyam Sunder Tejpal</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">Platinum</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">9848</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">1212732138</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">25/11/2024 10:34:45</TableCell>
                                        <TableCell style={{ color: "white" }} align="right">
                                            <Button style={{ background: "green", color: "white" }}>Approved</Button>
                                        </TableCell>
                                        <TableCell style={{ color: "white" }} align="right">

                                            <Button style={{ background: "red", color: "white" }}>Cancel</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
            };
            {
                pannelId === 2 && (
                    <div style={{ background: "#343434", height: "500px", color: "white" }}>
                        <h4 style={{ color: "#D3d3d3" }}>Total : {filteredRows.length} </h4>
                        <TextField
                            label="Search"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ color: "white", background: "white" }}
                        />

                        {/* Table */}
                        <TableContainer>

                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ color: "white" }}>GrowniverseId</TableCell>
                                        <TableCell style={{ color: "white" }}>Name</TableCell>
                                        <TableCell style={{ color: "white" }}>Package</TableCell>
                                        <TableCell style={{ color: "white" }}>Un-Paid</TableCell>
                                        <TableCell style={{ color: "white" }}>7 Day Income</TableCell>
                                        <TableCell style={{ color: "white" }}>Total Income</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ color: "white" }}>{row.GrowniverseId}</TableCell>
                                            <TableCell style={{ color: "white" }}>{row.Name}</TableCell>
                                            <TableCell style={{ color: "white" }}>{row.Package}</TableCell>
                                            <TableCell style={{ color: "white" }}>{row.UnPaid}</TableCell>
                                            <TableCell style={{ color: "white" }}>{row.SevenDayIncome}</TableCell>
                                            <TableCell style={{ color: "white" }}>{row.TotalIncome}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
            }
            {
                pannelId === 4 && (
                    <div className="" style={{
                        background: "#343434", height: "auto", width: "100%",
                        justifyContent: "space-between", alignItems: "center", textAlign: "center",
                        padding: "10px"
                    }}>
                        <div className="row" style={{ justifyContent: "space-between" }}>
                            <div className="col-4" style={{
                                background: "Blue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                    24 hour
                                 </h3>
                                 <h2>
                                    200
                                 </h2>
                            </div>
                            <div className="col-4" style={{
                                background: "skyblue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                    7 Days
                                 </h3>
                                 <h2>
                                    200
                                 </h2>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: "space-between" }}>
                        <div className="col-4" style={{
                                background: "skyblue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                    1 Month
                                 </h3>
                                 <h2>
                                    200
                                 </h2>
                            </div>
                            <div className="col-4" style={{
                                background: "skyblue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                   Total
                                 </h3>
                                 <h2>
                                    200
                                 </h2>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: "space-between" }}>
                        <div className="col-4" style={{
                                background: "skyblue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                    Un-paid
                                 </h3>
                                 <h1>
                                    200
                                 </h1>
                            </div>
                            <div className="col-4" style={{
                                background: "skyblue", height: "200px", borderRadius: "10%",
                                margin: "20px",
                                alignItems:"center"
                            }}>
                                 <h3>
                                    Transfered
                                 </h3>
                                 <h2>
                                    200
                                 </h2>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default KycAdmin;
