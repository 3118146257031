import React from 'react';
import InnerBanner from '../components/InnerBanner';
import PrivacyPolicySection from '../components/PrivacyPolicySection';

const PrivacyPolicy = () => {
    return <>
        <InnerBanner pageName='Privacy Policy' />
        <PrivacyPolicySection />
    </>
};

export default PrivacyPolicy;