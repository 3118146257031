import React from "react";
import { Link } from "react-router-dom";

const PriceWidget: React.FC = () => {
  return (
    <div className="widget widget-price">
      <h6 className="widget-title">Price</h6>
      <div className="widget-content">
        <Link to={'#'} className="btn btn-primary" >
          Get course
        </Link>
        <span className="fw-bold text-success lead ms-3">Free</span>
      </div>
    </div>
  );
};

export default PriceWidget;
