import "./Design/Dashboard.css"
const Income = () => {
    return <>
        <section style={{ padding: "20px", background: "#343434" }}>
            <div className="row todayIncome income">
                <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                    <h3 style={{ color: "white" }}>Today's Income</h3>
                </div>
                <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                    <h2 style={{ color: "white" }}>₹100</h2>
                </div>
            </div>
            <div className="row sevenIncome income">
                <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                    <h3 style={{ color: "white" }}>7 Day's Income</h3>
                </div>
                <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                    <h2 style={{ color: "white" }}>₹200</h2>
                </div>
            </div>
            <div className="row monthIncome income">
                <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                    <h3 style={{ color: "white" }}>Monthly Income</h3>
                </div>
                <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                    <h2 style={{ color: "white" }}>₹300</h2>
                </div>
            </div>
            <div className="row totalIncome income" >
                <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                    <h3 style={{ color: "white" }}>Total Income</h3>
                </div>
                <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                    <h2 style={{ color: "white" }}>₹500</h2>
                </div>
            </div>
            <div className="row unpaidIncome income" >
                <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                    <h3 style={{ color: "white" }}>Un-Paid Balance</h3>
                </div>
                <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                    <h2 style={{ color: "white" }}>₹10</h2>
                </div>
            </div>
        </section>
    </>
};

export default Income;