import React from 'react';
import InnerBanner from '../components/InnerBanner';
import GetInTouch from '../components/GetInTouch';
import AdditionalContactInfo from '../components/AdditionalContactInfo';
const ContactPage = () => {
    return <>
        <InnerBanner pageName='Contact Us' />
        <GetInTouch />
        <AdditionalContactInfo />
    </>
};

export default ContactPage;