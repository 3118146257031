import "../assets/css/package.css"
import basic from "../Photos/Package/Basic_Package.png"
import { Button, Link } from "@mui/material";
import "../components/WelcomeSection.css";
var packageInfo = { postarLink: basic, description: "Basic Package", packageAmount: 2000, discountAmount: 1500, actualAccount: 948 };
const PackageCourses = () => {
    return <>
        <section style={{ background: "#343434" }}>
            <div style={{ textAlign: "center", padding: "10px", borderColor: "white", borderStyle: "inset", marginBottom: "10px" }}>
                <img src={packageInfo.postarLink} itemType="png" width={"300px"} height={"450px"} alt="Growniverse Packages"/>
                <div style={{ background: "#899ba1" }}>
                <h2 style={{color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF"}}>
                    {packageInfo.description}
                </h2>  
                <h2 style={{ color: "red",background:"#343434" }}>₹{packageInfo.actualAccount}</h2>
                </div>
                <div  className="header" style={{ textAlign: "left"}}>
                    <h5>
                        <ol style={{ listStyleType: "upper-roman" }}>
                            <li><Link style={{color:"white"}}>Marketing - 10 Videos - 40 Hrs</Link></li>
                            <li><Link style={{color:"white"}}>Dotnet - 20 Videos - 30 Hrs</Link></li>
                            <li><Link style={{color:"white"}}>React Js - 5 Videos - 30 Hrs</Link></li>
                        </ol>
                    </h5>
                </div>
                <Button variant="contained" style={{ background: "#fa9602" }}>
                    <Link style={{ color: "black" }} href="/payment">🛒 BuyNow</Link>
                </Button>
            </div>
        </section>
    </>
};

export default PackageCourses;