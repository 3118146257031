import React from 'react';
import Avatar from 'react-avatar';
import "../components/WelcomeSection.css"
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import GrowniverseLogoFull from "../Photos/Logo/growniverse-logo-full.svg";
const UserHeader = () => {

    // const openModal = () => {
    //     setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    // const isHomePage = location.pathname === '/';
    // const isActive = (path: string) => location.pathname === path ? 'active' : '';

    return (
        <>
            <section className='row headerBackground' style={{display: "flex", justifyContent: "space-between", height: "80px"}}>
                <div className='col-3' style={{ background: "", paddingLeft: "20px",alignContent:"center", textAlign: "left" }}>
                    <img src={GrowniverseLogo} width={"70px"} height={"100%"} alt='Growniverse'/>
                </div>
                <div className='col-5' style={{ background: "",alignContent:"center",textAlign:"center"}}>
                <img src={GrowniverseLogoFull} width={"100%"} alt='GrowniverseLogo'/>
                </div>
                <div className='col-3 row'
                    style={{
                        textAlign: "right",
                        paddingRight:"40px",
                        justifyContent:"end",
                        alignContent:"center",
                    }}>
                    {/* <span className='col-6'>Logout</span> */}
                    <Avatar className='col-4' name="Robin Sharma" size="40" round={true} />
                </div>
            </section>
        </>
    );
};

export default UserHeader;
