import React from 'react';
import aboutImg from '../assets/img/about.jpg';

const AboutSection = () => {
  return (
    <section className="space-ptb bg-f9">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img className="img-fluid" alt="" src={aboutImg} />
          </div>
          <div className="col-md-6">
            <h2>About Us</h2>
            <p className="mb-4">
              Growniverse is your Digital Learning Platform Where you can learn new knowledge and skills without any physical distance barrier. You just not only get classes but you will get live webinars and specially Real Life Experience of Entrepreneurship.
            </p>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3 mb-md-0 mb-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="2208" data-speed="2000">100</h3>
                  </div>
                  <p className="mb-0 fw-6">Happy Students</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 mb-md-0 mb-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="250" data-speed="2000">35+</h3>
                  </div>
                  <p className="mb-0 fw-6">Our Courses</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="130" data-speed="2000">8</h3>
                  </div>
                  <p className="mb-0 fw-6">Our Teachers</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="26" data-speed="2000">20</h3>
                  </div>
                  <p className="mb-0 fw-6">Awards Won</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
