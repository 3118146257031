// Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-bottom bg-light">
      <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="social-icon text-md-start text-center mb-3 mb-md-0">
                <h4 style={{textDecoration:"underline"}}>Explore</h4>
                <p>
                  <span><Link to="/privacy-policy" target='_top'>Privacy Policy</Link></span><br/>
                  <span><Link to="/terms" target='_top'>Terms & Conditions</Link></span><br/>
                  <span><Link to="/refund-policy" target='_top'>Refund Policy</Link></span><br/>
                  <span><Link to="/contact" target='_top'>Contact Us</Link></span><br/>
                  <span><Link to="/about" target='_top'>About Us</Link></span><br/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="social-icon-round icon-sm text-md-start text-center mb-3 mb-md-0">
                <ul>
                <li> <Link to={'mailto:admin@growniverse.com'} target='_blank'><i className="fa fa-envelope"></i></Link></li> 
                <li><Link to={'https://www.instagram.com/growniverseofficial/'} target='_blank'><i className="fab fa-instagram"></i></Link></li>
                <li><Link to={'https://youtube.com/@growniverse?si=HipqeODMzh2ZDsLl'} ><i className="fab fa-youtube"></i></Link></li>
                <li><Link to={'https://t.me/growniverse'} target='_blank'><i className="fab fa-telegram"></i></Link></li>
                <li><Link to={'#'} target='_blank'><i className="fab fa-facebook-f"></i></Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="copyright text-md-end text-center">
                <p className="mb-0 small">© Copyright 2024 <Link to="#">Growniverse</Link> All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
