import React from 'react';
import HomeBanner from '../components/home-banner/HomeBanner';
import FeatureInfo from '../components/FeatureInfo';
import CourseCategoryList from '../components/course/CourseCategoryList';
import CourseList from '../components/CourseList';
import WelcomeSection from '../components/WelcomeSection';
import StatisticsSection from '../components/StatisticsSection';
import AchieversSection from '../components/AchieversSection';
import Testimonials from '../components/Testimonials';

const HomePage = () => {
    return <>
        <HomeBanner />
        <FeatureInfo />
        <WelcomeSection />
        <CourseCategoryList />
        <StatisticsSection />
        <CourseList />
        <AchieversSection />
        <Testimonials />
    </>
};

export default HomePage;