import React from "react";
import { Link } from "react-router-dom";

const SocialShareWidget: React.FC = () => {
  return (
    <div className="widget">
      <h6 className="widget-title">Social share</h6>
      <div className="widget-content">
        <div className="social-icon-round">
          <ul>
            <li>
              <Link to="/facebook">
                <i className="fab fa-facebook-f"></i>
              </Link>
            </li>
            <li>
              <Link to="/twitter">
                <i className="fab fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to="/linkedin">
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </li>
            <li>
              <Link to="/instagram">
                <i className="fab fa-instagram"></i>
              </Link>
            </li>
            <li>
              <Link to="/google">
                <i className="fab fa-google"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SocialShareWidget;
