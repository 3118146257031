import React from 'react';
import business from '../../Photos/Courses/Business.jpg';
import health from '../../Photos/Courses/Health.jpg';
import sharemarket from '../../Photos/Courses/ShareMarket.jpg';
import communication from '../../Photos/Courses/Communication.jpg';
import personality from '../../Photos/Courses/Personality.jpg';
import ielets from '../../Photos/Courses/IELETS.jpg';
import law from '../../Photos/Courses/Law.jpg';
import itImg from '../../assets/img/course-category/it.jpg';
import { Link } from 'react-router-dom';
import "../BackgroundImages.css"
const CourseCategoryList = () => {
  const categories = [
    { title: 'Business & Management', img: business },
    { title: 'Health & Nutrition', img: health },
    { title: 'IT & Software', img: itImg },
    { title: 'Personality Development', img: personality },
    { title: 'Share Market & Crypto Currency', img: sharemarket },
    { title: 'Communication & Presentation Skills', img: communication },
    { title: 'IELTS & English Speaking', img: ielets },
    { title: 'LAW Management', img: law },
  ];

  return (
    <section className="space-ptb BgSpace" >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-title text-center"> 
              <h2 style={{color:"white"}}>Choose Your Next Step to Success</h2>
              <p>
              The World need Dreamer's and the World Need Doers but above All the World Need Dreamer's Who Do.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {categories.slice(0, 4).map((category, index) => (
            <div key={index} className="col-md-3 col-sm-6">
              <Link to={'#'}>
                <div className="categories">
                  <img className="img-fluid" src={category.img} alt={category.title} />
                  <h6 className="categories-title">{category.title}</h6>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="row">
          {categories.slice(4).map((category, index) => (
            <div key={index} className="col-md-3 col-sm-6">
              <Link to={'#'}>
                <div className="categories mb-md-0"  style={{height:"auto"}}>
                  <img className="img-fluid" src={category.img} alt={category.title} style={{height:"auto"}} />
                  <h6 className="categories-title">{category.title}</h6>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CourseCategoryList;
