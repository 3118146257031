import React from 'react';

import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
const RefundPolicySection = () => {
  return (
    <section>
      <div style={{ padding: "10px" }}>
        <h6>Growniverse Refund Policy</h6>
        <p>
          At Growniverse, we strive to provide the Best Possible Digital Learning Experience for our students. However, we understand that sometimes circumstances may change, and you may need to cancel your enrollment in one of our online digital courses.
        </p>
        <h6>Cancellation Policy</h6>
        <p>
          <ul style={{ listStyleType: "square" }}>
            <li>
              If you cancel your enrollment within 24 hours of purchasing an online digital course or package you will receive a refund with 18 % deduction.
            </li>
            <li>
              To cancel your enrollment, please contact our support team via email at mail us :- admin@growniverse.com within 24 hours of purchasing the course or package.
            </li>
            <li>
              Please include your Growniverse ID, Course or Package Name, and a well defined reason for cancellation in your email.
            </li>
            <li>
              Refunds will be processed within 3-5 business days of receiving your cancellation request.
            </li>
          </ul>
        </p>

        <h6>Exceptions</h6>
        <p>
          <ul style={{ listStyleType: "square" }}>
            <li>
              Refunds will not be issued for cancellations made after 24 hours of purchasing the course or package.
            </li>
            <li>
              Refunds will not be issued for courses or packages that have already been accessed or downloaded.</li>
          </ul>
        </p>
        <h6>Changes to This Policy</h6>
        <ul style={{ listStyleType: "square" }}>
          <li>
            Growniverse reserves the right to modify or update this cancellation policy at any time without prior notice.
          </li>
        </ul>
        <p>
          If you have any questions or concerns about this policy, please don't hesitate to contact our support team.
        </p>
        <img src={GrowniverseLogo} width={"20%"} alt='Growniverse'/>
        <p>
          GROWNIVERSE<br />
          A UNIVERSE WHERE YOU GROW
        </p>
      </div>
    </section>
  );
};

export default RefundPolicySection;
