import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserNameByGId } from '../handler/LoginAndSingupHandler';
import axios from 'axios';
import { putUserSignOpt } from '../handler/LoginAndSingupHandler';
import { putUserLogin } from '../handler/LoginAndSingupHandler';
import { Alert } from '@mui/material';
type LoginAndSignupPopupProps = {
  onClose: () => void;
};
const LoginAndSignupPopup: React.FC<LoginAndSignupPopupProps> = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState('login');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  const [userName, setUserName] = useState("");
  const [loginUserName, setLoginUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [loginNameError, setloginNameError] = useState("");
  const [loginUserValid, setLoginUserValid] = useState(false);
  const getUserNameByGID = async (event: any) => {
    var growniverseid = event.target.value;
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setLoginUserName(response.data.fullName);
        setloginNameError("");
        setLoginUserValid(true);
      }
      else {
        setloginNameError(response.message);
        setLoginUserName("");
        setLoginUserValid(false);
      }
    }
  }

  const getUserNameBySGID = async (event: any) => {
    var growniverseid = event.target.value;
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setUserName(response.data.fullName);
        setUserNameError("");

      }
      else {
        setUserNameError(response.message);
        setUserName("");

      }
    }
  }
  const [formData, setFormData] = useState({});
  const [invalidPassword,setInvalidPassword] = useState(false);
  const handleInputChange = (e:any) => {
    const { name, value } = e.target; // Get name and value from the input
    if(name==="Password")
    {
      setInvalidPassword(false);
    }
    setFormData((prev) => ({ ...prev, [name]: value })); // Update state dynamically
  };
  const loginClick = async () => {
    debugger
    var response = await putUserLogin(formData);
    localStorage.setItem("appkey", response.appkey)
    if(response.statusCode===200)
    {
        window.location.href="/emailconfirmation"
    }
    else if(response.statusCode === 401)
    {
      setInvalidPassword(true);
    }
  }

  const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
  const [isValid, setIsValid] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [password, setPassowrd] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const checkValidPassword = (event: any) => {
    const inputPassword = event.target.value;
    setPassowrd(event.target.value)
    setIsValid(passwordRegex.test(inputPassword)); // Validate password
    if (confirmPassword === inputPassword) {
      setIsMatch(true);
    }
    else {
      setIsMatch(false);
    }
  };
  const checkConfirmPassword = (event: any) => {
    const inputPassword = event.target.value;
    setConfirmPassowrd(inputPassword);
    if (password === inputPassword) {
      setIsMatch(true);
    }
    else {
      setIsMatch(false);
    }
  };
  type State = {
    name: string;
    state_code: string;
  };
  const [states, setStates] = useState<State[]>([]);
  const [loadingStates] = useState(false);
  const fetchCountries = async () => {
    try {

      const response = await axios.get("https://countriesnow.space/api/v0.1/countries/states");
      const selectedCountryData = response.data.data.find((c: any) => c.name === "India");

      if (selectedCountryData) {
        setStates(selectedCountryData.states);
      } else {
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const userSingOpt = async (event: any) => {
    if (userName.length > 0) {
      var userDataFromForm = event.target;
      if (userDataFromForm[8].value === userDataFromForm[9].value) {
        const userData = {
          "sponsorGrowniverseId": userDataFromForm[0].value,
          "fullName": userDataFromForm[1].value,
          "phoneNumber": userDataFromForm[2].value,
          "email": userDataFromForm[3].value,
          "state": userDataFromForm[5].value,
          "address": userDataFromForm[6].value,
          "pincode": userDataFromForm[7].value,
          "password": userDataFromForm[8].value,
        };
        var response = await putUserSignOpt(userData);
        localStorage.setItem("userid", response.userId);
      }
    }
  }

  useEffect(() => {

    fetchCountries();
  }, []);


  return (
    <div className="modal login fade show" id="loginModal" role="dialog" aria-labelledby="loginModalLabel" aria-modal="true" style={{ display: 'block', paddingLeft: 0 }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title" id="loginModalLabel">Log in &amp; Register</h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ul className="nav nav-tabs nav-tabs-02 justify-content-center" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === 'login' ? 'active' : ''}`}
                  id="login-tab"
                  onClick={() => setActiveTab('login')}
                  role="tab"
                  aria-controls="login"
                  aria-selected={activeTab === 'login'}
                  href="#login"
                >
                  <span>Log in</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === 'register' ? 'active' : ''}`}
                  id="register-tab"
                  onClick={() => setActiveTab('register')}
                  role="tab"
                  aria-controls="register"
                  aria-selected={activeTab === 'register'}
                  href="#register"
                >
                  <span>Register</span>
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              {activeTab === 'login' && (
                <div className="tab-pane fade active show" id="login" role="tabpanel" aria-labelledby="login-tab">
                  <div className='row'>
                    <div className='col mb-3'>
                      <label>Growniverse ID <span style={{ color: "red" }}>*</span></label>
                      <input type="text" name='GrowniverseId' onChange={handleInputChange} className='form-control' onBlur={getUserNameByGID} disabled={loginUserName.length > 0} required />
                      <span style={{ color: 'green', alignItems: 'center' }}>{loginUserName}</span>
                      <h6 style={{ color: 'red', alignItems: 'center' }}>
                        {loginNameError.length > 0 && (
                          <Alert severity="error" variant="outlined">
                            Please Enter Valid Login Growniverse ID
                          </Alert>
                        )}
                      </h6>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col mb-3'>
                      <label>Passoword</label>
                      <input type="password" name='Password' className="form-control" placeholder="Password" onChange={handleInputChange} disabled={!loginUserValid} />
                      <h6 style={{ color: 'red', alignItems: 'center' }}>
                        {invalidPassword && (
                          <Alert severity="error" variant="outlined">
                            Invalid Password
                          </Alert>
                        )}
                      </h6>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col text-center'>
                      <button type="submit" className="btn btn-primary" onClick={loginClick}>Sign In</button>
                    </div>
                  </div>
                </div>
              )}





              {activeTab === 'register' && (
                <div className="tab-pane fade active show" id="register" role="tabpanel" aria-labelledby="register-tab">
                  <form onSubmit={userSingOpt}>
                    <div className='row'>
                      <div className='col mb-3'>
                        <label>Sponsor Growniverse ID <span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' onBlur={getUserNameBySGID} disabled={userName.length > 0} required />
                        <span style={{ color: 'green', alignItems: 'center' }}>{userName}</span>
                        <h6 style={{ color: 'red', alignItems: 'center' }}>
                          {userNameError.length > 0 && (
                            <Alert severity="error" variant="outlined">
                              Please Enter Valid Sponsor Growniverse ID
                            </Alert>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col mb-3'>
                        <label>Name <span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' placeholder="Name" required />
                      </div>
                      <div className='col mb-3'>
                        <label>Phone Number <span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' placeholder="Phone Number" required />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group">
                        <label>Email Address <span style={{ color: "red" }}>*</span></label>
                        <input type="email" className='form-control' placeholder="Email Address" required />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col mb-3'>
                        <label>Select Country</label>
                        <select className='form-control' disabled>
                          <option value="India" selected>India</option>
                        </select>
                      </div>
                      <div className='col mb-3'>
                        <label>Select State <span style={{ color: "red" }}>*</span></label>
                        {loadingStates ? (
                          <p>Loading states...</p>
                        ) : (
                          <select disabled={!states.length} className='form-control' required>
                            <option value="">-- Select State --</option>
                            {states.map((state, index) => (
                              <option key={index} value={state.state_code}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col mb-3'>
                        <label>Address <span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' placeholder="Address" required />
                      </div>
                      <div className='col mb-3'>
                        <label>Pincode <span style={{ color: "red" }}>*</span></label>
                        <input type="number" className='form-control' placeholder="Pincode" required />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col mb-3'>
                        <label>Password <span style={{ color: "red" }}>*</span></label>
                        <div className="input-wrapper position-relative password-field">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            className='form-control'
                            onChange={checkValidPassword}
                            required />
                          <button className='eye-btn' type="button" onClick={togglePasswordVisibility}>
                            <i className="eye-icon">👁️</i>
                          </button>
                        </div>
                      </div>
                      <div className='col mb-3'>
                        <label>Confirm Password <span style={{ color: "red" }}>*</span></label>
                        <div className="input-wrapper position-relative password-field">
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            placeholder="Confirm Password" className='form-control'
                            disabled={!isValid}
                            onChange={checkConfirmPassword}
                            required />
                          <button
                            type="button"
                            className='eye-btn'
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <i className="eye-icon">👁️</i>
                          </button>
                        </div>
                      </div>
                      {password.length > 0 && (
                        isValid ? (
                          <p style={{ color: "green" }}>Password is Valid.</p>
                        ) : (
                          <p style={{ color: "red" }}>Password must include uppercase, lowercase, number, special character, no spaces, and be 8-16 characters.</p>
                        )
                      )}
                      {confirmPassword.length > 0 && (
                        isMatch ? (
                          <p style={{ color: "green" }}>Confirm Password Is Valid.</p>
                        ) : (
                          <p style={{ color: "red" }}>Password Confirm Password.</p>
                        )
                      )}
                    </div>
                    <div className="form-group">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                        <label className="form-check-label ps-1" htmlFor="flexCheckDefault">
                          I agree to the <Link to={'/privacypolicy'} target='_blank'>Privacy Policy </Link>and{" "}
                          <Link to={'/termsconditions'} target='_blank'>Terms & Conditions</Link>.
                        </label>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col mb-1 text-center'>
                        <button type="submit" className="btn btn-primary" disabled={!isMatch}>
                          Register Account
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      Already have an account? <Link to="#">Sign In</Link>
                    </div>
                  </form>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAndSignupPopup;
