import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LoginAndSignupPopup from './LoginAndSignup';
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isHomePage = location.pathname === '/';
  const isActive = (path: string) => location.pathname === path ? 'active' : '';

  return (
    <header className={`headerBackground header ${isHomePage ? 'position-absolute' : 'position-static'} header-sticky default-transparent`}>
      <nav className="navbar navbar-static-top navbar-expand-lg px-3 px-md-5">
        <div className="container-fluid position-relative px-0">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
            <i className="fas fa-align-left"></i>
          </button>
          <Link to="/" className={`me-4 logo d-inline-block h3 mb-0 ${isHomePage ? 'text-white' : 'text-dark'}`}>
          <img src={GrowniverseLogo} width={"80px"} height={"100%"} alt='Growniverse'/>
          </Link>
          <div className="navbar-collapse collapse">
            <ul className="navbar-nav me-auto">
              <li className={`nav-item ${isActive('/')}`}>
                <Link className={`dropdown-item ${isHomePage ? 'text-lg-white' : ''}`} to={'/'}>
                  Home
                </Link>
              </li>
              <li className={`nav-item ${isActive('/about')}`}>
                <Link className={`nav-link ${isHomePage ? 'text-lg-white' : ''}`} to='/about'>
                  About Us
                </Link>
              </li>
              <li className={`nav-item ${isActive('/contact')}`}>
                <Link className={`nav-link ${isHomePage ? 'text-lg-white' : ''}`} to='/contact'>
                  Contact Us
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <Link to={'#'} className={`dropdown-item ${isHomePage ? 'text-lg-white' : ''}`} data-bs-toggle="dropdown">
                  Courses <i className="fas fa-chevron-down fa-xs"></i>
                </Link>
                <ul className="dropdown-menu megamenu dropdown-menu">
                  <li>
                    <div className="row g-0">
                      <div className="col-sm-6 col-md-4">
                        <ul className="list-unstyled mt-lg-1">
                          <li>
                            <Link className={`dropdown-item `} to={routes.courseDetail}>
                              <span>Design</span>
                            </Link>
                          </li>
                          <li>
                            <Link className={`dropdown-item `} to={routes.courseDetail}>
                              <span>Development</span>
                            </Link>
                          </li>
                          <li>
                            <Link className={`dropdown-item `} to={routes.courseDetail}>
                              <span>Marketing</span>
                            </Link>
                          </li>
                          <li>
                            <Link className={`dropdown-item `} to={routes.courseDetail}>
                              <span>IELTS</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
          <div className="woo-action">
            <ul className="list-unstyled">
              <li>
                <button className='btn btn-primary' onClick={() => openModal()}>Login</button>
                {isModalOpen && <LoginAndSignupPopup onClose={closeModal} />}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
