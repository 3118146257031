import React from 'react';
import Slider from 'react-slick';

import Rahul from "../Photos/Achievers/Rahul.png";
import Kartik from "../Photos/Achievers/Kartik.png";
import Nikhil from "../Photos/Achievers/Nikhil.png";

const AchieversSection = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,  
        settings: {
          slidesToShow: 1,  
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,  
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='space-ptb achievers-section' style={{background:"#343434", borderStyle:"inset",borderColor:"white"}}>
      <div className='container'>
        <h2 className='mb-5 text-center' style={{color:"white"}}>All Time Achievers</h2>
        <Slider {...settings}>
          <div className='slide-item px-3'>
            <div className="card" >
              <img className='img-fluid' src={Kartik} alt={Kartik}/>
            </div>
          </div>
          <div className='slide-item px-3'>
            <div className="card" >
              <img className='img-fluid' src={Rahul} alt={Rahul}/>
            </div>
          </div>
          <div className='slide-item px-3'>
            <div className="card" >
              <img className='img-fluid' src={Nikhil} alt={Nikhil}/>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default AchieversSection;
