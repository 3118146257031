import React from 'react';
import { Link } from 'react-router-dom';

const GetInTouch = () => {
  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <div className="section-title">
              <h2>Get In Touch</h2>
              <p>The Difference Between Dreams and Reality is called Action.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <form className="row fill-form mb-4 mb-md-0 form-flat-style">
              <div className="mb-3 col-sm-6">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              <div className="mb-3 col-sm-6">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" placeholder="" />
              </div>
              <div className="mb-3 col-sm-6">
                <label className="form-label">Subject</label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              <div className="mb-3 col-sm-6">
                <label className="form-label">Phone</label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              <div className="mb-3 col-sm-12">
                <label className="form-label">Message</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
              </div>
              <div className="mb-3 col-sm-12">
                <button type="submit" className="btn btn-primary">Send us</button>
              </div>
            </form>
          </div>
          <div className="col-md-3">
            <h4 className="mb-4">Contact Detail</h4>
            {/* <p className="mb-2"><b className="text-dark">Whatsapp Chat:</b>+91-80 913 22 146
            <br/><b>(Mon-Fri 10:00AM to 05:00PM)</b>
            <br/><span style={{color:"red",fontSize:"10px"}}>This Number Available for Whatsapp Chat Support only.</span>
            </p> */}
            <p className="mb-4"><b className="text-dark">Mail us:</b><Link to={'mailto:admin@growniverse.com'}>admin@growniverse.com</Link></p>
            <div className="social-icon-round icon-sm">
              <ul>
                <li> <Link to={'mailto:admin@growniverse.com'} target='_blank'><i className="fa fa-envelope"></i></Link></li>
                <li><Link to={'https://www.instagram.com/growniverseofficial/'} target='_blank'><i className="fab fa-instagram"></i></Link></li>
                <li><Link to={'https://youtube.com/@growniverse?si=HipqeODMzh2ZDsLl'} ><i className="fab fa-youtube"></i></Link></li>
                <li><Link to={'https://t.me/growniverse'} target='_blank'><i className="fab fa-telegram"></i></Link></li>
                <li><Link to={'#'} target='_blank'><i className="fab fa-facebook-f"></i></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
