import React from 'react';
import InnerBanner from '../components/InnerBanner';
import RefundPolicySection from '../components/RefundPolicySection';

const RefundPolicy = () => {
    return <>
        <InnerBanner pageName='Refund Policy' />
        <RefundPolicySection />
    </>
};

export default RefundPolicy;