import React from "react";

const CourseOverview: React.FC = () => {
  return (
    <div className="border mb-4">
      <h6 className="text-dark px-4 py-2 bg-light mb-0">Curriculum</h6>
      <div className="p-4 border-top">
        <span className="lead text-dark fw-6">Overview</span>
        <ul className="list-unstyled mt-3">
          <li className="d-sm-flex align-items-center border-bottom pb-3 mb-3">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 1 :</span>
            <span>Getting Started</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>45 min</span>
            </div>
          </li>
          <li className="d-sm-flex align-items-center border-bottom pb-3 mb-3">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 2 :</span>
            <span>YouTube Video</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>30 min</span>
            </div>
          </li>
          <li className="d-sm-flex align-items-center border-bottom pb-3 mb-3">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 3 :</span>
            <span>Live lesson - zoom</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>20 min</span>
            </div>
          </li>
        </ul>

        <span className="lead text-dark fw-6">Beginner</span>
        <ul className="list-unstyled mt-3 mb-0">
          <li className="d-sm-flex align-items-center border-bottom pb-3 mb-3">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 1 :</span>
            <span>Getting Started</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>45 min</span>
            </div>
          </li>
          <li className="d-sm-flex align-items-center border-bottom pb-3 mb-3">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 2 :</span>
            <span>YouTube Video</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>30 min</span>
            </div>
          </li>
          <li className="d-sm-flex align-items-center">
            <i className="flaticon-list-1 fa-sm me-2 text-primary"></i>
            <span className="me-4">Lecture 3 :</span>
            <span>Live lesson - zoom</span>
            <div className="ms-auto">
              <i className="far fa-clock text-primary me-2"></i>
              <span>20 min</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseOverview;
