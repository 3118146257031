import React from 'react';
import InnerBanner from '../components/InnerBanner';
import CourseDescription from '../components/course/CourseDescription';
import CourseOverview from '../components/course/CourseOverview';
import PriceWidget from '../components/widgets/PriceWidget';
import CourseInfoWidget from '../components/widgets/CourseInfoWidget';
import SocialShareWidget from '../components/widgets/SocialShareWidget';
import courseDetailImg from '../assets/img/course-detail.jpg';
import { Link } from 'react-router-dom';

const CourseDetailPage = () => {
    return <>
        <InnerBanner pageName='Course Detail' />
        <div className='space-ptb course-details'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7 col-xl-8'>
                        <img className="img-fluid  mb-4" src={courseDetailImg} alt="" />
                        <h4 className="news-title mb-2">PMP Exam Prep Seminar - PMBOK Guide 6</h4>
                        <ul className="list-unstyled d-sm-flex mt-3 mb-4">
                            <li className="d-inline">
                                <Link className="btn btn-outline-dark-hover btn-sm me-3 mb-sm-0 mb-3" to="/wishlist">
                                    Wishlist
                                </Link>
                            </li>
                            <li className="d-inline social-share">
                                <Link className="btn btn-outline-primary-hover btn-sm me-3 mb-sm-0 mb-3" to="#">
                                    Share
                                </Link>
                                <ul>
                                    <li><Link to="/share/facebook"><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="/share/twitter"><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to="/share/linkedin"><i className="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="/share/pinterest"><i className="fab fa-pinterest-p"></i></Link></li>
                                </ul>
                            </li>
                            <li className="d-inline">
                                <Link className="btn btn-outline-dark-hover btn-sm" to="/gift-course">
                                    Gift this course
                                </Link>
                            </li>
                        </ul>
                        <CourseDescription />
                        <CourseOverview />
                    </div>
                    <div className='col-md-5 col-xl-4 mt-5 mt-md-0'>
                        <div className='widgets'>
                            <PriceWidget />
                            <CourseInfoWidget />
                            <SocialShareWidget />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default CourseDetailPage;