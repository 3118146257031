import React from 'react';
import InnerPolicyBanner from '../components/InnerPolicyBanner';
import TermsConditionsSection from '../components/TermsConditionsSection';
const LoginTermsConditions = () => {
    return (
        <>
            <InnerPolicyBanner pageName='Terms & Conditions' />
            <TermsConditionsSection />
        </>
    );
};

export default LoginTermsConditions;
