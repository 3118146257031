import React from 'react';
interface InnerBannerProps {
  pageName: string;
}

const InnerPolicyBanner: React.FC<InnerBannerProps> = ({ pageName }) => {
  return (
    <section className="inner-header bg-holder bg-overlay-black-90" style={{ backgroundImage: "url('https://themes.potenzaglobalsolutions.com/html/guruma/images/bg/03.jpg')" }}>
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-md-6 text-center text-md-start mb-2 mb-md-0">
            <h1 className="breadcrumb-title mb-0 text-white">{pageName}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerPolicyBanner;
