import React from 'react';
import InnerBanner from '../components/InnerBanner';
import TermsConditionsSection from '../components/TermsConditionsSection'
const TermsConditions = () => {
    return <>
        <InnerBanner pageName='Terms & Conditions' />
        <TermsConditionsSection /> 
    </>
};

export default TermsConditions;