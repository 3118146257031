import React, { useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import UserHeader from './UserHeader';
import Pannel from './Pannel';

interface LayoutProps {
  children: React.ReactNode;
}

const UserLayout: React.FC<LayoutProps> = ({ children }) => {
  const hasRun = useRef(false); 
  useEffect(() => {
    if (!hasRun.current) {
      const appKey = localStorage.getItem("appkey");
      if (appKey === null) {
        // window.location.href = "/";
      }
      hasRun.current = true;
    }
  }, [])
  return (
    <>
      <UserHeader />
      <Pannel />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default UserLayout;
