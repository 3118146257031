import "./Design/Dashboard.css"
const Pannel = () => {
    return <>
        <section>
            <div style={{ height: "50px", background: "#343434"}}>
                <ul style={{display:"flex",listStyle:"none"}}>
                    <li style={{color:"white",padding:"10px"}}>Home</li>
                    <li style={{color:"white", padding:"10px"}}>Profile</li>
                    <li style={{color:"white", padding:"10px"}}>KYC</li>
                    <li style={{color:"white", padding:"10px"}}>Payout</li>
                    <li style={{color:"white", padding:"10px"}}>Courses</li>
                    <li style={{color:"white", padding:"10px"}}>My Courses</li>
                    <li style={{color:"white", padding:"10px"}}>Genealogy</li>
                    <li style={{color:"white", padding:"10px"}}>Tools</li>
                    <li style={{color:"white", padding:"10px"}}>Support</li>
                </ul>
            </div>
        </section>
    </>
};

export default Pannel;