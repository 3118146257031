import "../assets/css/package.css"
import scanner from "../Photos/Payment/Scanner.jpeg"
import { Button, Input } from "@mui/material";
import "../components/WelcomeSection.css"
const Payment = () => {
    return <>
        <section style={{background:'#343434'}}>
            <div style={{ textAlign: "center", padding: "10px", borderColor: "white", borderStyle: "inset", marginBottom: "10px" }}>
                <img src={scanner} itemType="png" width={"300px"} height={"450px"} alt="Scanner Growniverse"/>
                <h3 style={{ background: "#228B22", color: "#D3D3D3",marginTop:"10px" }}>
                    UPI ID - rs421312-1@okaxis
                </h3>
                <h2 style={{ background: "#899ba1" }}>
                    Bank Name - Punjab National Bank
                </h2>
                <h2 style={{ background: "#899ba1" }}>
                    Account Number - 7974002100000327
                </h2>
                <h2 style={{ background: "#899ba1" }}>
                    IFSC CODE - PUNB797400
                </h2>
                <div className="header" style={{height:"100px"}}>
                    <h4> <Input style={{color:"white"}} placeholder="Enter Transaction Id"/></h4>
                    <Button variant="contained" style={{ background: "#fa9602" }}>
                        Submit
                    </Button>
                </div>
            </div>
        </section>
    </>
};

export default Payment;