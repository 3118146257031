import React from 'react';
import { Link } from 'react-router-dom';

interface InnerBannerProps {
  pageName: string;
}

const InnerBanner: React.FC<InnerBannerProps> = ({ pageName }) => {
  return (
    <section className="inner-header bg-holder bg-overlay-black-90" style={{ backgroundImage: "url('https://themes.potenzaglobalsolutions.com/html/guruma/images/bg/03.jpg')" }}>
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-md-6 text-center text-md-start mb-2 mb-md-0">
            <h1 className="breadcrumb-title mb-0 text-white">{pageName}</h1>
          </div>
          <div className="col-md-6">
            <ol className="breadcrumb d-flex justify-content-center justify-content-md-end ms-auto">
              <li className="breadcrumb-item"><Link to="/" className='text-white'><i className="fas fa-home me-1"></i>Home</Link></li>
              <li className="breadcrumb-item active"><span>{pageName}</span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerBanner;
