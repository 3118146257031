import React from 'react';
import "./WelcomeSection.css"

const WelcomeSection = () => {
  return (
    <section className="space-sm-ptb welcomeBackground">
      <div className="container">
        <div className="row d-flex align-items-center text-center">
          <div className="col-md-12">
            <h2 className='mb-4' style={{color:"black"}}>Welcome to Growniverse</h2>
            <h5 className='mb-0'>Vision and Mission</h5>
            <p className="mb-0">
              The Vision and Mission of Growniverse is to Create a Universe, Where every young youth talk about Dreams, Success and Growth instead of Wasting Precious Time in Unproductive Activities.
              The Most Important Mission of Growniverse is to Create an Ethical Environment. Where Every Single Person Respect Every Individual Specially Their Parents, We are Trying to Give the Environment of Moral And Values, So That Every Individual Never decide to Go in Any kind of Bad Society and any of the Bad Activity Physically as well as Mentally.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
