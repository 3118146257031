import React from 'react';
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
const TermsConditionsSection = () => {
  return (
    <section>
      <div style={{ padding: "10px" }}>
        <h5>Growniverse Terms and conditions</h5>
        <p>
          At Growniverse, we also offer our Digital courses through an Affiliate model, where our affiliates promote our courses to their audiences. This policy outlines the terms and conditions of our affiliate program.
          <br /><br />
          <p>
            Affiliates Should Digitally Accept all the Policies written in Growniverse 
            <b><a href="/affiliateagreement" style={{ color: "black" }} target='_blank'> Affiliate Aggreement</a></b>
          </p>
        </p>
        <h6>No Income or Commission Guarantees</h6>
        <p>
          <ol>
            <li>Growniverse does not guarantee any fixed income or commission to its affiliates.</li>
            <li>Any income or commission earned by affiliates is solely dependent on their individual performance and sales generated through their unique affiliate link.</li>
          </ol>
        </p>
        <h6>Affiliate Responsibilities</h6>
        <p>
          <ol>
            <li>Affiliates are responsible for promoting Growniverse online digital courses in a professional and ethical manner.</li>
            <li>Affiliates must comply with all applicable laws, regulations, and industry standards when promoting our courses.</li>
            <li>Affiliates must take care that they never misguide or mislead any of the individual for any kind of personal benifit.</li>
          </ol>
        </p>
        <h6>Growniverse Responsibilities</h6>
        <p>
          <ol>
            <li>Growniverse will provide affiliates with access to a dashboard to track their sales, commissions and growth.</li>
            <li>Growniverse will pay affiliates their earned commissions on a regular basis, as specified in the affiliate agreement.</li>
          </ol>
        </p>
        <h6>Disclaimer</h6>
        <p>
          <ol>
            <li>Growniverse disclaims any liability for any damages or losses incurred by affiliates or their customers as a result of promoting our online digital courses.</li>
            <li>Growniverse reserves the right to modify or terminate the affiliate program at any time without prior notice.</li>
          </ol>
        </p>
        <p>
          By participating in the Growniverse affiliate program, affiliates acknowledge that they have read, understood, and agree to abide by this policy.
        </p>
        <img src={GrowniverseLogo} width={"20%"} alt='Growniverse'/>
        <p>
          GROWNIVERSE<br />
          A UNIVERSE WHERE YOU GROW
        </p>
      </div>
    </section>
  );
};

export default TermsConditionsSection;
