import "../assets/css/package.css"
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import "../components/WelcomeSection.css"
const Hold = () => {
    return <>
        <section style={{ background: "#343434" ,height:"500px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className="header" style={{height:"400px",width:"80%",borderRadius:"30px",
                display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p style={{textAlign:"center",fontSize:"20px",font:"Sans-serif",color:"white"}}>
                    Hi, <b>Shyam Sunder Tejpal</b><br/>

                    We are excited to Welcome you in our Universe,<br/>
                    Your package activation is currently in progress.<br/>
                    We will notify you via email as soon as the activation is complete.<br/>

                    Best regards...<br/><br/>
                    <img src={GrowniverseLogo} width={"30%"} alt="Growniverse"/>
                </p>
            </div>
        </section>
    </>
};

export default Hold;