import React, { useEffect, useRef } from 'react';
import UserHeader from '../Dashboard/UserHeader';

interface LayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<LayoutProps> = ({ children }) => {
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      const appKey = localStorage.getItem("appkey");
      if (appKey === null) {
        window.location.href = "/";
      }
      hasRun.current = true;
    }
  }, [])
  return (
    <>
      <UserHeader />
      <main>{children}</main>
    </>
  );
};

export default AdminLayout;
