import React from "react";

const CourseDescription: React.FC = () => {
  return (
    <div className="border mb-4">
      <h6 className="text-dark px-4 py-2 bg-light mb-0">Description</h6>
      <div className="p-4 border-top">
        <span className="lead text-dark fw-6">Course Description</span>
        <p className="mt-2">
          Where there is no change. Briefly imagine that you are not going to
          live and discover your unfulfilled dreams. Instead, you continue doing
          what you have in the past. What will life be like in 10 years time?
          Step out, every step you take you get older, days pass, weeks pass.
          Notice how your body is, how your mind is, how you feel about staying
          on this path.
        </p>
        <p className="mb-4">
          Walk out into your future to the 10 year point. Walk out 10 years into
          your future and feel how it feels to carry on doing the same thing.
          This path is just like today, with one difference: you have 10 fewer
          years remaining in your life.
        </p>
        <span className="lead text-dark fw-6">Main features</span>
        <ul className="list mt-2">
          <li>
            <i className="far fa-circle"></i> Use a past defeat as a motivator.
          </li>
          <li>
            <i className="far fa-circle"></i> Most people believe that success
            is difficult.
          </li>
          <li>
            <i className="far fa-circle"></i> Success is something of which we
            all want more.
          </li>
          <li>
            <i className="far fa-circle"></i> Give yourself the power of
            responsibility.
          </li>
          <li>
            <i className="far fa-circle"></i> Remind yourself the only thing
            stopping you is yourself.
          </li>
          <li>
            <i className="far fa-circle"></i> They’re wrong – it’s not!
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseDescription;
