import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
const EmailConfirmation = () => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    useEffect(() => {
        const userId = query.get("userId");
        const token = query.get("token");
        const appKey = localStorage.getItem("appkey");
        if ((userId && token) || appKey) {
        }
        else {
            window.location.href = "/";
        }
    }, [query]);
    return <>
        <section style={{ background: "#343434", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="header" style={{
                height: "400px", width: "80%", borderRadius: "30px",
                display: "flex", justifyContent: "center", alignItems: "center", margin: "170px 30px 170px 30px"
            }}>
                <p style={{ textAlign: "center", fontSize: "20px", font: "Sans-serif", color: "white" }}>
                    {query.get("userId") && (
                        <>
                            <p>
                                Your Email Confirmation is Under Process, Please Stay on this page.
                            </p>
                        </>
                    )}
                    {localStorage.getItem("appkey") && (
                        <>
                            <p>
                                <h2 style={{ color: "white" }}>Your Email is Not Confirmend</h2>
                                <p>Please Check your Gmail and Confirm Email First.</p>
                            </p>
                        </>
                    )}
                    <img src={GrowniverseLogo} width={"20%"} alt='Growniverse'/>
                </p>
            </div>
        </section>
    </>
};
export default EmailConfirmation;