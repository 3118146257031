import React from 'react';
import InnerPolicyBanner from '../components/InnerPolicyBanner';
import AgreementSection from '../components/AgreementSection';
const LoginAgreement = () => {
    return (
        <>
            <InnerPolicyBanner pageName='Affiliate Agreement' />
            <AgreementSection />
        </>
    );
};

export default LoginAgreement;
