import Avatar from "react-avatar";
import "./Design/Dashboard.css"
import "../components/WelcomeSection.css";

const DashboardProfile = () => {
    
    return <>
        <section style={{ background: "#343434", width: "100%" }}>
            <div className="container dashboardProfile">
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <Avatar name="Robin Sharma" size="100" round={true} />
                </div>
                <div style={{ textAlign: "center" }}>

                    <div style={{ color: "black", background: "#899ba1", borderRadius: "30px", width: "300px", display: "inline-grid" }}>
                        <h2>Robin Sharma</h2>
                        <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>Founder</h3>
                        <h3>Robinverse</h3>
                        <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}> Admin</h2>
                    </div>
                </div>
            </div>
            

        </section>
    </>
};

export default DashboardProfile;